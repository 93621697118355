import React from "react";
import { TextField } from "@mui/material";
import { ResetButton } from "./resetButton";
import { useDspAuditVarianceContext } from "../../../context";

type NewQuantityInputProps = {
    lightyearId: number
}

type NewQuantityInputState = {
    newQuantity: number
    originalQuantity: number
}

function NewQuantityInput(props: NewQuantityInputProps): JSX.Element {
    const { lightyearId } = props;
    const { getQuantity, setNewQuantity } = useDspAuditVarianceContext();
    const [newQuantityInputState, setNewQuantityInputState] = React.useState<NewQuantityInputState>({
        newQuantity: 0,
        originalQuantity: 0
    });

    const resetQuantityCallback = React.useCallback(() => {
        setNewQuantityInputState(prevState => {
            return {
                ...prevState,
                newQuantity: prevState.originalQuantity
            }
        });
    }, [])

    const onChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setNewQuantityInputState(prevState => {
            return {
                ...prevState,
                newQuantity: e.target.valueAsNumber,
            };
        });
    }

    React.useEffect(() => {
        const quantity = getQuantity(lightyearId);
        setNewQuantityInputState({
            newQuantity: quantity,
            originalQuantity: quantity,
        })
    }, [lightyearId, getQuantity]);

    const { newQuantity } = newQuantityInputState;

    React.useEffect(() => {
        setNewQuantity(lightyearId, newQuantity);
    }, [lightyearId, newQuantity, setNewQuantity])

    return (
        <TextField
            InputProps={{
                endAdornment: (<ResetButton resetQuantityCallback={resetQuantityCallback} />),
                inputProps: {
                    min: 0
                }
            }}
            onChange={onChangeQuantity}
            type="number"
            value={newQuantityInputState.newQuantity}
            />
        );
}

export { NewQuantityInput }