import { IconButton } from "@mui/material";
import RestoreIcon from '@mui/icons-material/Restore';

type ResetButtonProps = {
    resetQuantityCallback: () => void
}

function ResetButton(props: ResetButtonProps): JSX.Element {
    const onClickResetButton = (e: React.MouseEvent<HTMLButtonElement>): void => {
        props.resetQuantityCallback();
    }

    return (
        <IconButton onClick={onClickResetButton}>
            <RestoreIcon fontSize="small"/>
        </IconButton>
    );
}

export { ResetButton }