import { createBrowserRouter } from "react-router-dom"
import { AllVehicleAudits } from "./components/dsv/home/allVehicleAudits";
import { CombinedSetup } from "./components/dsv/setup";
import { Layout } from "./components/base";
import { AuditDetails } from "./components/dsv/audit";
import { VehicleRoutes } from "./components/dsv/vehicleRoutes";
import { PartsRoutes } from "./components/dsp/partsRoutes";
import { Options } from "./components/options";
import { OngoingAudits } from "./components/dsp/audit/ongoingAudits";
import { Setup } from "./components/dsp/setup";
import { BinSelectionView } from "./components/dsp/audit/selection/selectionView";
import { AuditDetailsView } from "./components/dsp/audit/auditDetails/auditDetailsView";
import { VarianceView } from "./components/dsp/audit/variance/varianceView";
import { DspPrintView } from "./components/dsp/print";
import { DsvPrintView } from "./components/dsv/print";
import { AdminView } from "./components/admin";

const appRouter = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "",
                element: <Options />,
            },
            {
                path: "/admin",
                element: <AdminView />
            },
            {
                path: "/parts",
                element: <PartsRoutes />,
                children: [
                    {
                        path: "/parts",
                        element: <OngoingAudits />
                    },
                    {
                        path: "/parts/print",
                        element: <DspPrintView />
                    },
                    {
                        path: "/parts/audit",
                        element: <OngoingAudits />
                    },
                    {
                        path: "/parts/audit/:auditId",
                        element: <AuditDetailsView />
                    },
                    {
                        path: "/parts/audit/selection/:auditId",
                        element: <BinSelectionView />
                    },
                    {
                        path: "/parts/audit/:auditId/variance",
                        element: <VarianceView />
                    },
                    {
                        path: "/parts/setup",
                        element: <Setup />
                    }
                ]
            },
            {
                path: "/vehicles",
                element: <VehicleRoutes />,
                children: [
                    {
                        path: "/vehicles",
                        element: <AllVehicleAudits />
                    },
                    {
                        path: "/vehicles/audits",
                        element: <AllVehicleAudits />
                    },
                    {
                        path: "/vehicles/audits/:auditId",
                        element: <AuditDetails />
                    },
                    {
                        path: "/vehicles/print",
                        element: <DsvPrintView />
                    },
                    {
                        path: "/vehicles/setup",
                        element: <CombinedSetup />
                    }
                ]
            }
        ]
    }
]);

export { appRouter };