import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { GenericDataGrid } from "../../../../../base/dataGrid/genericDataGrid";
import { DspAuditBinSimple, DspAuditVariance } from "types/dsp/audits";
import { Checkbox } from "@mui/material";
import { Utilities } from "../../../../../../utilities";
import { NewQuantityInput } from "./newQuantityInput";
import { useDspAuditVarianceContext } from "../../context";

type MappedRow = {
    id: number
    partNumber: string
    bins: DspAuditBinSimple[]
    vendor: string
    dollarVariance: number
    quantityVariance: number
    scannedCount: number
    scannedBy: string
    scanDate: Date
    partsMovement: number
    isSelected: boolean
    newQty: number
    onHandCount: number
}

type ColumnParamsType = {
    selectedRows: Set<number>
    onChangeIsRowSelectedCallback: (lightyearId: number) => void
}

const columns = (columnParams: ColumnParamsType): GridColDef[] => [
    {
        field: "id",
        flex: 1,
        headerName: "ID",
        hideable: true
    },
    {
        field: "isSelected",
        flex: 0.5,
        headerName: "Select",
        hideable: false,
        renderCell: (params) => (
            <Checkbox
                checked={columnParams.selectedRows.has(params.row.id)}
                onChange={() => columnParams.onChangeIsRowSelectedCallback(params.row.id)}
                />
        )
    },
    {
        field: "partNumber",
        flex: 1,
        headerName: "Part Number",
        hideable: false
    },
    {
        field: "bins",
        flex: 0.5,
        headerName: "Bin",
        hideable: false,
        renderCell: (params) => (<span>{params.value.map((bin: DspAuditBinSimple) => bin.binName).join(", ")}</span>)
    },
    {
        field: "vendor",
        flex: 0.5,
        headerName: "Vendor",
        hideable: false
    },
    {
        field: "onHandCount",
        flex: 0.75,
        headerName: "On Hand Count",
        hideable: false
    },
    {
        field: "dollarVariance",
        flex: 0.75,
        headerName: "$ Variance",
        hideable: false,
        renderCell: (params) => (<span>{Utilities.displayAsCurrency(params.value)}</span>)
    },
    {

        field: "quantityVariance",
        flex: 0.75,
        headerName: "Qty Variance",
        hideable: false
    },
    {
        field: "scannedCount",
        flex: 0.5,
        headerName: "Scanned",
        hideable: false
    },
    {
        field: "scannedBy",
        flex: 1,
        headerName: "Scanned By",
        hideable: true
    },
    {
        field: "scanDate",
        flex: 1,
        headerName: "Scan Date",
        hideable: true
    },
    {
        field: "partsMovement",
        flex: 0.5,
        headerName: "Movement",
        hideable: false
    },
    {
        field: "newQty",
        flex: 0.75,
        headerName: "New Qty",
        hideable: false,
        renderCell: (params) => (<NewQuantityInput lightyearId={params.row.id} />)
    },
    {
        field: "",
        flex: 2,
        headerName: "",
        hideable: false
    }
];

function PendingVarianceDataGrid(): JSX.Element {
    const { auditVariances, selectedPending, onChangeIsPendingVarianceSelected } = useDspAuditVarianceContext();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        if (!auditVariances) {
            return;
        }

        setMappedRows(auditVariances.map((auditVariance: DspAuditVariance) => {
            return {
                id: auditVariance.lightyearPartId,
                partNumber: auditVariance.partNumber,
                bins: auditVariance.bins,
                vendor: auditVariance.vendor,
                dollarVariance: auditVariance.dollarVariance,
                quantityVariance: auditVariance.quantityVariance,
                scannedCount: auditVariance.scannedCount,
                scannedBy: auditVariance.auditorName,
                scanDate: auditVariance.auditDate,
                partsMovement: auditVariance.movementSinceScan,
                isSelected: false,
                newQty: auditVariance.scannedCount,
                onHandCount: auditVariance.onHandCount
            };
        }));
    }, [auditVariances]);

    return (
        <GenericDataGrid
            columns={columns({
                selectedRows: selectedPending,
                onChangeIsRowSelectedCallback: onChangeIsPendingVarianceSelected
            })}
            columnVisibilityModel={{
                id: false,
                scannedBy: false,
                scanDate: false
            }}
            rows={mappedRows}
            />
    );
}

export { PendingVarianceDataGrid }