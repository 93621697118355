import React from "react";
import { PendingVarianceDataGrid } from "./pending";
import { ReScanVarianceDataGrid } from "./rescan";
import { useDspAuditVarianceContext } from "../context";

function VarianceDataGridResolver(): JSX.Element {
    const { auditVarianceType } = useDspAuditVarianceContext();
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch (auditVarianceType) {
                case "pending":
                    return (
                        <PendingVarianceDataGrid />
                    );
                case "rescans":
                    return (
                        <ReScanVarianceDataGrid />
                    );
                default: 
                    return (<></>);
            }
        });
    }, [auditVarianceType]);

    return (component);
}

export { VarianceDataGridResolver }