import React from "react";
import { useApiService } from "../../../../base/providers";
import { useParams } from "react-router-dom";
import { AuditVarianceTabType } from "../varianceTypes";
import { DspAuditBinSimple, DspAuditVariance, DspAuditVarianceChange, GetDspAuditVarianceResponse } from "types/dsp/audits";

type DspAuditVarianceContextType = {
    auditId: string
    auditName: string
    auditVariances: DspAuditVariance[]
    auditVarianceType: AuditVarianceTabType
    selectedPending: Set<number>
    selectedRescan: Set<number>
    getQuantity: (lightyearId: number) => number
    onChangeIsPendingVarianceSelected: (lightyearId: number) => void
    onChangeIsRescanVarianceSelected: (lightyearId: number) => void
    requestRescan: () => Promise<void>
    setAuditVarianceType: (type: AuditVarianceTabType) => void
    setNewQuantity: (lightyearId: number, newQuantity: number) => void
    updateBinVariance: () => Promise<void>
}

const DspAuditVarianceContext = React.createContext<DspAuditVarianceContextType | undefined>(undefined);

type DspAuditVarianceProviderProps = {
    children: React.ReactNode
}

type AuditVarianceStateType = {
    auditName: string
    auditVarianceQuantities: Map<number, number>
    auditVariances: DspAuditVariance[]
    auditVarianceType: AuditVarianceTabType
    selectedPending: Set<number>
    selectedRescan: Set<number>
}

function DspAuditVarianceProvider(props: DspAuditVarianceProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const { auditId } = useParams();

    const [auditVarianceState, setAuditVarianceState] = React.useState<AuditVarianceStateType>({
        auditName: "",
        auditVarianceQuantities: new Map<number, number>(),
        auditVariances: [],
        auditVarianceType: "pending",
        selectedPending: new Set<number>(),
        selectedRescan: new Set<number>()
    });

    const getAuditVariancesCallback = React.useCallback((): void => {
        if (!auditId) {
            return;
        }

        apiService.dsp.getAuditVariance(auditId)
            .then((response: GetDspAuditVarianceResponse) => {
                const auditVariances = response.auditVariances.filter((value: DspAuditVariance) => value.quantityVariance !== 0);

                const auditVarianceQuantities = new Map(auditVariances.map((value: DspAuditVariance) => [value.lightyearPartId, value.scannedCount]));

                setAuditVarianceState(prevState => {
                    return {
                        ...prevState,
                        auditName: response.audit.auditName,
                        auditVarianceQuantities: auditVarianceQuantities,
                        auditVariances: auditVariances,
                        selectedPending: new Set<number>(),
                        selectedRescan: new Set<number>(),
                    };
                });
            })
            .catch((err) => console.error("Unable to get audit", err));
    }, [apiService, auditId]);

    const updateBinVarianceCallback = React.useCallback((): Promise<void> => {
        return new Promise((resolve, reject) => {
            const mappedVariances = auditVarianceState.auditVariances
                .filter((value: DspAuditVariance) => auditVarianceState.selectedPending.has(value.lightyearPartId))
                .map((value: DspAuditVariance): DspAuditVarianceChange => {
                    return {
                        auditBinId: value.bins[0].binId,
                        lightyearId: value.lightyearPartId,
                        newOnHand: auditVarianceState.auditVarianceQuantities.get(value.lightyearPartId) || value.scannedCount
                    }; 
                });

            apiService.dsp.updateBinVariance(mappedVariances);

            apiService.dsp.updateBinVariance(mappedVariances)
                .then(() => getAuditVariancesCallback())
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }, [apiService, getAuditVariancesCallback, auditVarianceState.auditVariances, auditVarianceState.auditVarianceQuantities, auditVarianceState.selectedPending]);

    const requestRescanCallback = React.useCallback((): Promise<void> => {
        return new Promise((resolve, reject) => {
            const rescanVarianceBinIds = auditVarianceState.auditVariances
                .filter((value: DspAuditVariance) => auditVarianceState.selectedRescan.has(value.lightyearPartId))
                .flatMap((value: DspAuditVariance) => value.bins)
                .flatMap((value: DspAuditBinSimple) => value.binId);
            
            apiService.dsp.flagBinsForRescan(rescanVarianceBinIds)
                .then(() => getAuditVariancesCallback())
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }, [apiService, getAuditVariancesCallback, auditVarianceState.auditVariances, auditVarianceState.selectedRescan]);

    const setAuditVarianceTypeCallback = React.useCallback((type: AuditVarianceTabType): void => {
        setAuditVarianceState(prevState => {
            return {
                ...prevState,
                auditVarianceType: type
            };
        });
    }, []);

    const onChangeIsPendingVarianceSelectedCallback = React.useCallback((lightyearId: number) => {
        setAuditVarianceState(prevState => {
            const newSelected = new Set(prevState.selectedPending);

            if (newSelected.has(lightyearId)) {
                newSelected.delete(lightyearId);
            } else {
                newSelected.add(lightyearId);
            }
            return {
                ...prevState,
                selectedPending: newSelected,
            }
        });
    }, []);

    const onChangeIsRescanVarianceSelectedCallback = React.useCallback((lightyearId: number) => {
        setAuditVarianceState(prevState => {
            const newSelected = new Set(prevState.selectedRescan);

            if (newSelected.has(lightyearId)) {
                newSelected.delete(lightyearId);
            } else {
                newSelected.add(lightyearId);
            }
            return {
                ...prevState,
                selectedRescan: newSelected,
            };
        });
    }, []);

    const setNewQuantityCallback = React.useCallback((lightyearId: number, newQuantity: number): void => {
        setAuditVarianceState(prevState => {
            const newMap = prevState.auditVarianceQuantities;

            newMap.set(lightyearId, newQuantity);

            return {
                ...prevState,
                auditVarianceQuantities: newMap,
            };
        });
    }, []);

    const getQuantityCallback = React.useCallback((lightyearId: number): number => {
        var quantity = auditVarianceState.auditVarianceQuantities.get(lightyearId);

        return quantity || 0;
    }, [auditVarianceState.auditVarianceQuantities]);

    React.useEffect(() => {
        getAuditVariancesCallback();
    }, [getAuditVariancesCallback]);

    return (
        <DspAuditVarianceContext.Provider
            value={{
                auditId: auditId || "",
                auditName: auditVarianceState.auditName,
                auditVariances: auditVarianceState.auditVariances,
                auditVarianceType: auditVarianceState.auditVarianceType,
                selectedPending: auditVarianceState.selectedPending,
                selectedRescan: auditVarianceState.selectedRescan,
                getQuantity: getQuantityCallback,
                onChangeIsPendingVarianceSelected: onChangeIsPendingVarianceSelectedCallback,
                onChangeIsRescanVarianceSelected: onChangeIsRescanVarianceSelectedCallback,
                requestRescan: requestRescanCallback,
                setAuditVarianceType: setAuditVarianceTypeCallback,
                setNewQuantity: setNewQuantityCallback,
                updateBinVariance: updateBinVarianceCallback,
            }}
            >
            {props.children}
        </DspAuditVarianceContext.Provider>
    );
}

function useDspAuditVarianceContext(): DspAuditVarianceContextType {
    const context = React.useContext(DspAuditVarianceContext);

    if (!context) {
        throw new Error("useDspAuditVarianceContext must be used within a DspAuditVarianceProvider");
    }

    return context;
}

export { DspAuditVarianceProvider, useDspAuditVarianceContext }