import { Button, Chip } from "@mui/material";
import { useDspAuditVarianceContext } from "../../context";

function RescanTabButton() {
    const { auditVariances, setAuditVarianceType } = useDspAuditVarianceContext();
    
    const onClickRescanTabButton = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        setAuditVarianceType("rescans");
    }

    return (
        <Button onClick={onClickRescanTabButton}>
            Pending Rescans&nbsp;
            <Chip
                color="primary"
                label={auditVariances.length}
                />
        </Button>
    );
}

export { RescanTabButton }