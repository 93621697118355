import React from "react";
import { DspAuditBinSimple, DspAuditVariance } from "types/dsp/audits";
import { GenericDataGrid } from "../../../../../base/dataGrid/genericDataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { Checkbox, MenuItem, Select } from "@mui/material";
import { Utilities } from "../../../../../../utilities";
import { useDspAuditVarianceContext } from "../../context";

type MappedRow = {
    id: number
    bin: string
    partNumber: string
    vendor: string
    dollarVariance: number
    quantityVariance: number
    lastScannedBy: string
    isSelected: boolean
}

type ColumnParamsType = {
    selectedRows: Set<number>
    onChangeIsRowSelectedCallback: (lightyearId: number) => void
}

const columns = (columnParams: ColumnParamsType): GridColDef[] => [
    {
        field: "bin",
        flex: 1,
        headerName: "Bin",
        hideable: false
    },
    {
        field: "partNumber",
        flex: 1,
        headerName: "Part Number",
        hideable: false
    },
    {
        field: "vendor",
        flex: 1,
        headerName: "Vendor",
        hideable: false
    },
    {
        field: "dollarVariance",
        flex: 1,
        headerName: "$ Variance",
        hideable: false,
        renderCell: (params) => (
            <span>{Utilities.displayAsCurrency(params.value)}</span>
        )
    },
    {

        field: "quantityVariance",
        flex: 1,
        headerName: "Qty Variance",
        hideable: false
    },
    {
        field: "assignedUserSelection",
        flex: 2,
        headerName: "",
        hideable: false,
        renderCell: () => (
            <Select value={1}>
                <MenuItem value={1}>Not Yet Implemented</MenuItem>
            </Select>
        )
    },
    {
        field: "isSelected",
        flex: 0.5,
        headerName: "",
        hideable: false,
        renderCell: (params) => (
            <Checkbox
                checked={columnParams.selectedRows.has(params.row.id)}
                onChange={() => columnParams.onChangeIsRowSelectedCallback(params.row.id)}
                />
        )
    }
];

function ReScanVarianceDataGrid(): JSX.Element {
    const { auditVariances, selectedRescan, onChangeIsRescanVarianceSelected } = useDspAuditVarianceContext();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        if (!auditVariances) {
            return;
        }

        setMappedRows(auditVariances.map((auditVariance: DspAuditVariance) => {
            return {
                id: auditVariance.lightyearPartId,
                bin: auditVariance.bins.map((bin: DspAuditBinSimple) => bin.binName).join(", "),
                partNumber: auditVariance.partNumber,
                vendor: auditVariance.vendor,
                dollarVariance: auditVariance.dollarVariance,
                quantityVariance: auditVariance.quantityVariance,
                lastScannedBy: auditVariance.auditorName,
                isSelected: false
            };
        }));
    }, [auditVariances]);

    return (
        <GenericDataGrid
            columns={columns({
                selectedRows: selectedRescan,
                onChangeIsRowSelectedCallback: onChangeIsRescanVarianceSelected
            })}
            rows={mappedRows}
            />
    );
}

export { ReScanVarianceDataGrid }